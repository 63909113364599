import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import { MESSAGES_ROOT } from './constants'

class MessagesPortal extends Component {
  state = {
    isActive: false,
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  // TODO check if issue is resolved: https://github.com/facebook/react/issues/12615
  // remove set state in componentDidMount. Not ideal due to double render
  componentDidMount() {
    this.setState({ isActive: true })
  }

  componentWillUnmount() {
    this.setState({ isActive: false })
  }

  render() {
    // don't render on server
    if (!this.state.isActive) return null
    return ReactDOM.createPortal(
      this.props.children,
      document.getElementById(MESSAGES_ROOT),
    )
  }
}

export default MessagesPortal
