import styled, { css } from 'styled-components'

import { media } from '~styles/mediaQuery'
import { limitMaxWidth } from '~styles/constants'

export const maxWidthStyle = css`
  margin: 0 auto;
  ${media.mq1`
    max-width: ${limitMaxWidth.mq1};
  `};
  ${media.mq2`
    max-width: ${limitMaxWidth.mq2};
  `};
  ${media.mq3`
    max-width: ${limitMaxWidth.mq3};
  `};
  ${media.mq4`
    max-width: ${limitMaxWidth.mq4};
  `};
`

const MaxWidthWrapper = styled.div`
  ${maxWidthStyle};
`

export default MaxWidthWrapper
