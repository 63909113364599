import { TOASTS_TYPE } from '~components/Messages/Toasts/constants'

export const toastInitialState = {
  toasts: {
    [TOASTS_TYPE.RELOAD_WEB_PAGE]: false,
  },
}

export const toastActions = {
  addToast: (state, payload) => ({
    ...state,
    toasts: {
      ...state.toasts,
      [payload.type]: true,
    },
  }),
  removeToast: (state, payload) => ({
    ...state,
    toasts: {
      ...state.toasts,
      [payload.type]: false,
    },
  }),
}
