import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.mq1`
    margin: ${({ theme }) => `${theme.spacing[32]} auto ${theme.spacing[16]}`};
    max-width: 98%;
  `};
  ${media.mq2`
    max-width: 92%;
    margin: ${({ theme }) => `${theme.spacing[32]} auto ${theme.spacing[32]}`};
  `};
  ${media.mq34`
    margin: ${({ theme }) => `${theme.spacing[32]} auto ${theme.spacing[48]}`};
  `};
  ${media.mq3`
    max-width: 85.405%;
  `};
  ${media.mq4`
    max-width: 1024px;
  `};
`

export default Wrapper
