import styled from 'styled-components'

import Typography from '~components/Typography'

const InstagramLettering = styled(Typography)`
  display: inline-block;
  line-height: 1.8;
  margin-left: ${(props) => props.theme.spacing[2]};
`

export default InstagramLettering
