import React from 'react'
import AppProvider from '~components/AppProvider'
import AppLayout from '~components/AppLayout'
// import { TOASTS_TYPE } from '~components/Messages/Toasts/constants'
// import { useActions } from '~store/useStore'

// const Toasts = () => {
//   const { addToastReloadWebPage } = useActions(dispatch => ({
//     addToastReloadWebPage: () =>
//       dispatch({
//         type: 'addToast',
//         payload: { type: TOASTS_TYPE.RELOAD_WEB_PAGE },
//       }),
//   }))
//
//   useEffect(() => addToastReloadWebPage(), [])
//
//   return null
// }

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
)

export const wrapPageElement = ({ element }) => <AppLayout>{element}</AppLayout>

// todo send message to service worker:
// - client is ready
// - sw responds with: display toast
// - client dispatches action to display the toast
// - on click the toast triggers a reload
export const onServiceWorkerUpdateReady = () => {
  console.log('onServiceWorkerUpdateReady: -----------------')
  navigator.serviceWorker.getRegistration().then((registration) => {
    console.log('pre posting message: -----------------')

    console.log('registration', registration)

    // Check if new service worker is waiting
    if (!registration || !registration.waiting) {
      return
    }

    console.log('posting message: -----------------')

    // send skip waiting message to service worker
    registration.waiting.postMessage('skipWaiting')
  })
}
