import { css } from 'styled-components'

const mediaQuery = (...query) => (...rules) =>
  css`
    @media ${css(...query)} {
      ${css(...rules)};
    }
  `

export const breakpoint = {
  breakpointMin: 320,
  breakpointSmall: 600,
  breakpointMedium: 900,
  breakpointLarge: 1200,
  breakpointMax: 1800,
}

export const media = {
  mq1: mediaQuery`only screen and (max-width: ${(breakpoint.breakpointSmall - 1) / 16}em)`,
  mq2: mediaQuery`only screen and (min-width: ${breakpoint.breakpointSmall / 16}em) and (max-width: ${(breakpoint.breakpointMedium - 1) / 16}em)`,
  mq3: mediaQuery`only screen and (min-width: ${breakpoint.breakpointMedium / 16}em) and (max-width: ${(breakpoint.breakpointLarge - 1) / 16}em)`,
  mq4: mediaQuery`only screen and (min-width: ${breakpoint.breakpointLarge / 16}em)`,
  mqMin: mediaQuery`only screen and (max-width: ${breakpoint.breakpointMin / 16}em)`,
  mqMax: mediaQuery`only screen and (min-width: ${breakpoint.breakpointMax / 16}em)`,
  mq12: mediaQuery`only screen and (max-width: ${(breakpoint.breakpointMedium - 1) / 16}em)`,
  mq123: mediaQuery`only screen and (max-width: ${(breakpoint.breakpointLarge - 1) / 16}em)`,
  mq23: mediaQuery`only screen and (min-width: ${breakpoint.breakpointSmall / 16}em) and (max-width: ${(breakpoint.breakpointLarge - 1) / 16}em)`,
  mq234: mediaQuery`only screen and (min-width: ${breakpoint.breakpointSmall / 16}em)`,
  mq34: mediaQuery`only screen and (min-width: ${breakpoint.breakpointMedium / 16}em)`,
  print: mediaQuery`print`,
}
