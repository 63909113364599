import styled from 'styled-components'

import Typography from '~components/Typography'

import { darkColors } from './constants'

const Text = styled(Typography)`
  color: ${({ level, theme }) => darkColors(theme)[level]};
`

export default Text
