import React from 'react'
import PropTypes from 'prop-types'

import { CLOUDINARY_BASE_URL } from '~utils/constants'

import InstagramImg from './InstagramImg'
import InstagramLink from './InstagramLink'

const InstagramImage = ({ href, image, alt, slot, inView }) => (
  <InstagramLink
    href={href}
    slot={slot}
    target="_blank"
    rel="noopener noreferrer"
  >
    <InstagramImg
      alt={alt}
      loading="lazy"
      src={
        inView
          ? `${CLOUDINARY_BASE_URL}/c_fill,dpr_1.0,g_auto:no_faces,h_200,w_200/${image}`
          : undefined
      }
      srcSet={
        inView
          ? `${CLOUDINARY_BASE_URL}/c_fill,dpr_1.0,g_auto:no_faces,h_200,w_200/${image} 1x, ${CLOUDINARY_BASE_URL}/c_fill,dpr_2.0,g_auto:no_faces,h_200,w_200/${image} 2x`
          : undefined
      }
      crossOrigin="anonymous"
    />
  </InstagramLink>
)

InstagramImage.propTypes = {
  href: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  slot: PropTypes.number.isRequired,
  inView: PropTypes.bool.isRequired,
}

export default InstagramImage
