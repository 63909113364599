/**
 *
 * IconClose
 *
 */

import React from 'react'

import Svg from '../Svg'

const IconClose = (props) => (
  <Svg viewBox="0 0 21.9 21.9" {...props}>
    <path d="M14.1 11.3a.5.5 0 0 1 0-.7l7.5-7.5c.2-.2.3-.5.3-.7s-.1-.5-.3-.7L20.2.3a1 1 0 0 0-.7-.3 1 1 0 0 0-.7.3l-7.5 7.5c-.2.2-.5.2-.7 0L3.1.3C2.9.1 2.6 0 2.4 0s-.5.1-.7.3L.3 1.7c-.2.2-.3.5-.3.7s.1.5.3.7l7.5 7.5c.2.2.2.5 0 .7L.3 18.8c-.2.2-.3.5-.3.7s.1.5.3.7l1.4 1.4c.2.2.5.3.7.3s.5-.1.7-.3l7.5-7.5c.2-.2.5-.2.7 0l7.5 7.5c.2.2.5.3.7.3s.5-.1.7-.3l1.4-1.4c.2-.2.3-.5.3-.7s-.1-.5-.3-.7l-7.5-7.5z" />
  </Svg>
)

export default IconClose
