import styled from 'styled-components'

export const InstagramImagePlaceholder = styled.div`
  padding-top: 100%;
  border-radius: ${(props) => props.theme.spacing[2]};
  background: ${(props) => props.theme.palette.grey[50]};
  grid-area: ${(props) => `picture-${props.slot}`};
`

export default InstagramImagePlaceholder
