import styled from 'styled-components'

import { maxWidthStyle } from '~components/MaxWidthWrapper'

const Wrapper = styled.div`
  ${maxWidthStyle};
  position: absolute;
  bottom: ${({ theme }) => theme.spacing[8]};
  left: 50%;
  transform: translateX(-50%);
  pointer-events: all;
`

export default Wrapper
