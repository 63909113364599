import React from 'react'

import { useActions } from '~store/useStore'

import ToastWrapper from './ToastWrapper'
import Text from './Text'
import messages from './messages'
import { TOASTS_TYPE } from './constants'

const updateServiceWorkerIfWaiting = () => {
  // Get a Promise for the existing registration (which may be null)
  navigator.serviceWorker.getRegistration().then((registration) => {
    // Check if new service worker is waiting
    if (!registration || !registration.waiting) {
      return
    }

    // send skip waiting message to service worker
    registration.waiting.postMessage('skipWaiting')
  })
}

const ReloadWebPage = () => {
  const { removeToast } = useActions((dispatch) => ({
    removeToast: () =>
      dispatch({
        type: 'removeToast',
        payload: { type: TOASTS_TYPE.RELOAD_WEB_PAGE },
      }),
  }))

  const onClickHandler = () => {
    removeToast()
    updateServiceWorkerIfWaiting()
  }

  return (
    <ToastWrapper
      role="button"
      tabIndex="0"
      onKeyPress={onClickHandler}
      onClick={onClickHandler}
    >
      <Text align="center" type="body3">
        {messages.reloadWebPage}
      </Text>
    </ToastWrapper>
  )
}

ReloadWebPage.propTypes = {}

export default ReloadWebPage
