import { css } from 'styled-components'

import { breakpoint, media } from '~styles/mediaQuery'

export const setBodyScroll = isScroll => {
  if (isScroll) {
    document.body.style.overflow = 'auto'
  } else {
    document.body.style.overflow = 'hidden'
  }
}

export const calcFontSize = ({
  minFont,
  maxFont,
  minWidth = breakpoint.breakpointMin,
  maxWidth = breakpoint.breakpointMax,
}) =>
  `calc( ${minFont}px + (${maxFont} - ${minFont}) * ( (100vw - ${minWidth}px) / ( ${maxWidth} - ${minWidth}) ))`

export const fluidSpacing = value => ({
  min: `${(breakpoint.breakpointSmall / 100) * value}px`,
  max: `${(breakpoint.breakpointMax / 100) * value}px`,
  default: `${value}vw`,
})

const calcFluidSpacingValue = (values, mq) =>
  values.reduce((acc, value) => {
    if (typeof value === 'string' || value === 0) {
      return `${acc} ${value}`
    }
    if (typeof value === 'number') {
      return `${acc} ${fluidSpacing(value)[mq]}`
    }
    return acc
  }, '')

export const fluidSpacingFunc = props => css`
  ${props.map(
    prop =>
      `${prop.property}: ${calcFluidSpacingValue(prop.values, 'default')};`,
  )};
  ${media.mq1`
    ${props.map(
      prop => `${prop.property}: ${calcFluidSpacingValue(prop.values, 'min')};`,
    )}
  `};
  ${media.mqMax`
    ${props.map(
      prop => `${prop.property}: ${calcFluidSpacingValue(prop.values, 'max')};`,
    )}
  `};
`

export const mapThemePropsToCss = props =>
  props.type &&
  Object.keys(props.theme.typography[props.type]).map(mediaQuery =>
    mediaQuery === 'mq1234'
      ? props.theme.typography[props.type].mq1234
      : media[mediaQuery]`${props.theme.typography[props.type][mediaQuery]}`,
  )
