import React from 'react'
import PropTypes from 'prop-types'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const LinkExternal = ({ href, children, ...props }) => (
  <OutboundLink
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    {...props}
  >
    {children}
  </OutboundLink>
)

LinkExternal.propTypes = {
  href: PropTypes.string.isRequired,
}

export default LinkExternal
