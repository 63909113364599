import React from 'react'
import { ThemeProvider } from 'styled-components'

import theme from '~styles/theme'
import { StoreProvider } from '~store/useStore'

const AppProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    <StoreProvider>{children}</StoreProvider>
  </ThemeProvider>
)

export default AppProvider
