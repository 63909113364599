import React from 'react'
import { Link } from 'gatsby'

import Typography from '~components/Typography'
import { NAVIGATION } from '~utils/constants'
import MaxWidthWrapper from '~components/MaxWidthWrapper'

import Wrapper from './Wrapper'
import Content from './Content'

const Bar = () => (
  <Wrapper>
    <MaxWidthWrapper>
      <Content>
        <Typography
          as={Link}
          to={NAVIGATION.HOME.HOME}
          type="body3"
          color="white"
        >
          © {new Date().getFullYear()} Tania Ferreira
        </Typography>
        <Typography
          type="body3"
          as={Link}
          to={NAVIGATION.PRIVACY.PRIVACY}
          color="white"
        >
          Datenschutz
        </Typography>
      </Content>
    </MaxWidthWrapper>
  </Wrapper>
)

export default Bar
