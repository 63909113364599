import styled from 'styled-components'

import { maxWidthStyle } from '~components/MaxWidthWrapper'

const Wrapper = styled.div`
  ${maxWidthStyle};
  position: absolute;
  top: ${({ theme }) => theme.spacing[8]};
  right: ${({ theme }) => theme.spacing[8]};
  padding-left: ${({ theme }) => theme.spacing[8]};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: all;
`

export default Wrapper
