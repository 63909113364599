import styled from 'styled-components'

import { shadows } from '~styles/constants'

const ToastWrapper = styled.div`
  width: 500px;
  max-width: 100%;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.grey[900]};
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[32]}`};
  box-shadow: ${shadows[1]};
`

export default ToastWrapper
