// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-template-blog-list-index-js": () => import("./../src/components/TemplateBlogList/index.js" /* webpackChunkName: "component---src-components-template-blog-list-index-js" */),
  "component---src-components-template-blog-post-index-js": () => import("./../src/components/TemplateBlogPost/index.js" /* webpackChunkName: "component---src-components-template-blog-post-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebot-ernaehrung-js": () => import("./../src/pages/angebot/ernaehrung.js" /* webpackChunkName: "component---src-pages-angebot-ernaehrung-js" */),
  "component---src-pages-angebot-gruppenfitness-js": () => import("./../src/pages/angebot/gruppenfitness.js" /* webpackChunkName: "component---src-pages-angebot-gruppenfitness-js" */),
  "component---src-pages-angebot-js": () => import("./../src/pages/angebot.js" /* webpackChunkName: "component---src-pages-angebot-js" */),
  "component---src-pages-angebot-personal-training-js": () => import("./../src/pages/angebot/personal-training.js" /* webpackChunkName: "component---src-pages-angebot-personal-training-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-preise-js": () => import("./../src/pages/preise.js" /* webpackChunkName: "component---src-pages-preise-js" */),
  "component---src-pages-ueber-mich-js": () => import("./../src/pages/ueber-mich.js" /* webpackChunkName: "component---src-pages-ueber-mich-js" */)
}

