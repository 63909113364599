import React from 'react'

import GlobalMessages from './GlobalMessages'
import MessagesPortal from './MessagesPortal'
import Wrapper from './Wrapper'
import Toasts from './Toasts'

const Messages = () => (
  <MessagesPortal>
    <Wrapper>
      <GlobalMessages />
      <Toasts />
    </Wrapper>
  </MessagesPortal>
)

export default Messages
