import styled from 'styled-components'

import { icon } from '~styles/constants'

import { GLOBAL_MESSAGES_LEVEL } from '../constants'

const levelColor = ({ palette }) => ({
  [GLOBAL_MESSAGES_LEVEL.INFO]: palette.blue.dark,
  [GLOBAL_MESSAGES_LEVEL.SUCCESS]: palette.green.dark,
  [GLOBAL_MESSAGES_LEVEL.WARNING]: palette.orange.dark,
  [GLOBAL_MESSAGES_LEVEL.ERROR]: palette.red.dark,
})

const Svg = styled.svg`
  fill: ${({ level, theme }) => levelColor(theme)[level]};
  width: ${icon.size.xxsmall}px;
  position: absolute;
  top: ${({ theme }) => theme.spacing[12]};
  right: ${({ theme }) => theme.spacing[12]};
`

export default Svg
