import styled from 'styled-components'

import LinkExternal from '~components/LinkExternal'

const InstagramLink = styled(LinkExternal)`
  overflow: hidden;
  border-radius: ${(props) => props.theme.spacing[2]};
  position: relative;
  grid-area: ${(props) => `picture-${props.slot}`};
`

export default InstagramLink
