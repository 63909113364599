export const { NODE_ENV } = process.env
export const { GATSBY_GOOGLE_MAPS_API_KEY } = process.env

export const NAVIGATION = {
  HOME: {
    HOME: '/',
  },
  ABOUT: {
    ABOUT: '/ueber-mich',
  },
  SERVICE: {
    SERVICE: '/angebot',
    PERSONAL_TRAINING: '/angebot/personal-training',
    NUTRITION: '/angebot/ernaehrung',
    GROUP_FITNESS: '/angebot/gruppenfitness',
  },
  PRICE: {
    PRICE: '/preise',
    PERSONAL_TRAINING: '/preise#personal-training',
    NUTRITION: '/preise#ernährung',
    NEW_VERSION: '/preise#new-version',
    OTHER: '/preise#weiteres',
  },
  BLOG: {
    BLOG: '/blog',
    POST: '/blog/:post',
  },
  CONTACT: {
    CONTACT: '/kontakt',
  },
  ADMIN: {
    ADMIN: '/admin',
  },
  APPSHELL: {
    APPSHELL: '/app-shell',
  },
  PRIVACY: {
    PRIVACY: '/datenschutz',
  },
}

// human eye can not distinguish images with a higher pixel density
export const MAX_PIXELDENSITY = 2
export const MAX_PIXELDENSITY_ARRAY = [...Array(MAX_PIXELDENSITY).keys()]

export const isDev = NODE_ENV === 'development'

// prevent to create too many images in dev mode
export const ROUNDING = isDev ? 10 : 10

export const META_TAGS = {
  openGraphImagePrefix:
    'https://res.cloudinary.com/taniaferreira/image/upload/c_scale,q_65,c_fill,ar_1.9,g_auto,h_630',
  twitterImagePrefix:
    'https://res.cloudinary.com/taniaferreira/image/upload/c_scale,q_65,c_fill,ar_2,g_auto,h_512',
}

export const CONTACT = {
  PHONE: '+41 79 650 78 47',
  EMAIL: 'hallo@tania-ferreira.com',
}

export const MANA = {
  NAME: 'MANA',
  STREET: 'Lagerstrasse 10',
  CITY: '8004 Zürich',
  COUNTRY: 'Switzerland',
  URL: 'https://www.mana-ernaehrung.ch/',
  URL_SHORT: 'www.mana-ernaehrung.ch',
  GOOGLE_MAPS_URL:
    'https://www.google.com/maps/place/MANA+Ern%C3%A4hrung/@47.3773264,8.5317992,17z/data=!3m1!4b1!4m5!3m4!1s0x47900929ceb867bf:0xce9699c3a3314576!8m2!3d47.3773264!4d8.5339879',
}

export const CLOUDINARY_BASE_URL =
  'https://res.cloudinary.com/taniaferreira/image/upload'
export const CLOUDINARY_DEFAULT_PARAMETER = 'f_auto,q_80'
export const LOCALE = 'de-CH'
