import styled from 'styled-components'

import Typography from '~components/Typography'

import InstagramLettering from './InstagramLettering'

const InstagramHeader = styled(Typography)`
  display: inline-block;
  &:hover ${InstagramLettering} {
    background: linear-gradient(
      45deg,
      #405de6,
      #5851db,
      #833ab4,
      #c13584,
      #e1306c,
      #fd1d1d
    );
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export default InstagramHeader
