import React from 'react'
import { Transition, config, animated } from 'react-spring/renderprops'

import { useStore, useActions } from '~store/useStore'

import Message from './Message'
import Wrapper from './Wrapper'
import LifeBar from './LifeBar'
import Text from './Text'
import IconClose from './Icons/IconClose'

const spring = { ...config.default, precision: 0.1 }

const cancelMap = new WeakMap()
const GlobalMessages = () => {
  const { globalMessages } = useStore()
  const { removeMessage } = useActions((dispatch) => ({
    removeMessage: (uid) =>
      dispatch({
        type: 'removeGlobalMessage',
        payload: { uid },
      }),
  }))

  const customConfig = (message, state) =>
    state === 'leave' ? [{ duration: 10000 }, spring, spring] : spring
  const cancel = (message) => cancelMap.has(message) && cancelMap.get(message)()
  const leave = (message) => async (next, cancelMessage) => {
    cancelMap.set(message, cancelMessage)
    await next({ life: 0 })
    await next({ opacity: 0, height: 0, transform: 'translateX(100%)' }, true) // Inform Keyframes that is is the last frame
  }
  const onClickHandler = (message) => () => {
    cancel(message)
    removeMessage(message.uid)
  }

  return (
    <Wrapper>
      <Transition
        native
        items={globalMessages}
        keys={({ uid }) => uid}
        from={{ opacity: 0, height: 0, life: 1, transform: 'translateX(100%)' }}
        enter={{ opacity: 1, height: 'auto', transform: 'translateX(0)' }}
        leave={leave}
        onRest={({ uid }) => {
          removeMessage(uid)
        }}
        config={customConfig}
      >
        {(message) => ({ life, ...style }) => (
          <animated.div style={style}>
            <Message
              level={message.level}
              role="button"
              tabIndex="0"
              onKeyPress={onClickHandler(message)}
              onClick={onClickHandler(message)}
            >
              <Text type="body3" level={message.level}>
                {message.message}
              </Text>
              <IconClose level={message.level} />
              <LifeBar
                level={message.level}
                style={{ right: life.interpolate((l) => `calc(${l * 100}%)`) }}
              />
            </Message>
          </animated.div>
        )}
      </Transition>
    </Wrapper>
  )
}

GlobalMessages.propTypes = {}

export default GlobalMessages
