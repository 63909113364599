import React, { createContext, useReducer, useContext, useMemo } from 'react'

import { messageInitialState, messageActions } from './message'
import { toastInitialState, toastActions } from './toast'

// combine initial states
const initialState = {
  ...messageInitialState,
  ...toastInitialState,
}

const StoreContext = createContext(initialState)

// combine actions
const actions = {
  ...messageActions,
  ...toastActions,
}

const reducer = (state, action) => {
  const act = actions[action.type]
  const update = act(state, action.payload)
  return { ...state, ...update }
}

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStore = () => {
  const { state } = useContext(StoreContext)
  return state
}

// export const useSelectors = (reducer, mapStateToSelectors) => {
//   const [state] = reducer
//   const selectors = useMemo(() => mapStateToSelectors(state), [
//     mapStateToSelectors,
//     state,
//   ])
//   return selectors
// }

export const useActions = (mapDispatchToActions) => {
  const { dispatch } = useContext(StoreContext)
  const actions = useMemo(() => mapDispatchToActions(dispatch), [
    dispatch,
    mapDispatchToActions,
  ])
  return actions
}
