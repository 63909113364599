import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const InstagramWrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: 'picture-0 picture-1 picture-2 picture-3 picture-4 picture-5';
  width: 100%;
  ${media.mq12`
    grid-column-gap: ${props => props.theme.spacing[1]};
  `};
  ${media.mq34`
    grid-column-gap: ${props => props.theme.spacing[2]};
  `};
`

export default InstagramWrapper
