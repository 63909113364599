import shortid from 'shortid'

import { GLOBAL_MESSAGES_LEVEL } from '~components/Messages/GlobalMessages/constants'

export const messageInitialState = {
  globalMessages: [],
}

export const messageActions = {
  addGlobalSuccessMessage: (state, payload) => ({
    ...state,
    globalMessages: [
      ...state.globalMessages,
      {
        message: payload.message,
        level: GLOBAL_MESSAGES_LEVEL.SUCCESS,
        uid: shortid.generate(),
      },
    ],
  }),
  addGlobalInfoMessage: (state, payload) => ({
    ...state,
    globalMessages: [
      ...state.globalMessages,
      {
        message: payload.message,
        level: GLOBAL_MESSAGES_LEVEL.INFO,
        uid: shortid.generate(),
      },
    ],
  }),
  addGlobalWarningMessage: (state, payload) => ({
    ...state,
    globalMessages: [
      ...state.globalMessages,
      {
        message: payload.message,
        level: GLOBAL_MESSAGES_LEVEL.WARNING,
        uid: shortid.generate(),
      },
    ],
  }),
  addGlobalErrorMessage: (state, payload) => ({
    ...state,
    globalMessages: [
      ...state.globalMessages,
      {
        message: payload.message,
        level: GLOBAL_MESSAGES_LEVEL.ERROR,
        uid: shortid.generate(),
      },
    ],
  }),
  removeGlobalMessage: (state, payload) => ({
    ...state,
    globalMessages: state.globalMessages.filter(
      ({ uid }) => uid !== payload.uid,
    ),
  }),
}
