export const GLOBAL_MESSAGES_LEVEL = {
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
}

export const lightColors = ({ palette }) => ({
  [GLOBAL_MESSAGES_LEVEL.INFO]: palette.blue.light,
  [GLOBAL_MESSAGES_LEVEL.SUCCESS]: palette.green.light,
  [GLOBAL_MESSAGES_LEVEL.WARNING]: palette.orange.light,
  [GLOBAL_MESSAGES_LEVEL.ERROR]: palette.red.light,
})

export const mainColors = ({ palette }) => ({
  [GLOBAL_MESSAGES_LEVEL.INFO]: palette.blue.main,
  [GLOBAL_MESSAGES_LEVEL.SUCCESS]: palette.green.main,
  [GLOBAL_MESSAGES_LEVEL.WARNING]: palette.orange.main,
  [GLOBAL_MESSAGES_LEVEL.ERROR]: palette.red.main,
})

export const darkColors = ({ palette }) => ({
  [GLOBAL_MESSAGES_LEVEL.INFO]: palette.blue.dark,
  [GLOBAL_MESSAGES_LEVEL.SUCCESS]: palette.green.dark,
  [GLOBAL_MESSAGES_LEVEL.WARNING]: palette.orange.dark,
  [GLOBAL_MESSAGES_LEVEL.ERROR]: palette.red.dark,
})
