export const alpha = {
  primary: 0.87,
  secondary: 0.54,
  ternary: 0.47,
  disabled: 0.38,
}

export const icon = {
  size: {
    xxsmall: 12,
    xsmall: 20,
    small: 24,
    medium: 32,
    large: 48,
    xlarge: 64,
  },
}

export const palette = {
  primary: '#2D4262',
  secondary: '#F2BFAE',
  red: '#F44336',
  orange: '#FFA726',
  green: '#1ABC9c',
  blue: '#00afdc',
}

export const transitions = {
  easing: {
    easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
    easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
    easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
  },
  duration: {
    shortest: 150,
    shorter: 200,
    short: 250,
    standard: 300,
    complex: 375,
    enteringScreen: 225,
    leavingScreen: 195,
  },
}

export const shadowsArray = [
  ['0 1px 3px rgba(0,0,0,0.12)', '0 1px 2px rgba(0,0,0,0.24)'],
]

export const shadows = [
  'none',
  '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
]

export const zIndex = {
  navBar: 10,
}

export const limitMaxWidth = {
  mq1: '98%',
  mq2: '92%',
  mq3: '85.405%',
  mq4: '1024px',
}
