import React from 'react'
import { useInView } from 'react-intersection-observer'

import Typography from '~components/Typography'
import LinkExternal from '~components/LinkExternal'

import Wrapper from './Wrapper'
import StyledFooter from './StyledFooter'
import HeaderWrapper from './HeaderWrapper'
import InstagramFeed from './InstagramFeed'
import InstagramLettering from './InstagramLettering'
import InstagramHeader from './InstagramHeader'
import Bar from './Bar'

const Footer = () => {
  const [ref, inView] = useInView({ triggerOnce: true })
  return (
    <StyledFooter>
      <Wrapper ref={ref}>
        <HeaderWrapper>
          <InstagramHeader
            as={LinkExternal}
            href="https://www.instagram.com/_tania.ferreira_/"
          >
            <Typography type="body2" as="span">
              Follow me on&nbsp;
            </Typography>
            <InstagramLettering type="instagram" as="span">
              Instagram
            </InstagramLettering>
          </InstagramHeader>
        </HeaderWrapper>
        <InstagramFeed inView={inView} />
      </Wrapper>
      <Bar />
    </StyledFooter>
  )
}

export default Footer
