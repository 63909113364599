import styled from 'styled-components'

const StyledFooter = styled.footer`
  /*
  We use this to push the footer down on the offline-plugin-app-shell-fallback/index.html page
  this works because there is currently no other element next the footer
  this has to be revisited if other components are rendered next to the footer
   */
  &:only-child {
    margin-top: 100vh;
  }
`

export default StyledFooter
