import React from 'react'
import PropTypes from 'prop-types'
import { Transition, animated, config } from 'react-spring/renderprops'

import { useStore } from '~store/useStore'

import Wrapper from './Wrapper'
import ReloadWebPage from './ReloadWebPage'
import { TOASTS_TYPE } from './constants'

const Toasts = () => {
  const { toasts } = useStore()

  const items = [
    ...(toasts[TOASTS_TYPE.RELOAD_WEB_PAGE]
      ? [<ReloadWebPage key={TOASTS_TYPE.RELOAD_WEB_PAGE} />]
      : []),
  ]
  return (
    <Wrapper>
      <Transition
        native
        items={items}
        keys={(item) => item}
        from={{ opacity: 0, height: 0 }}
        enter={{ opacity: 1, height: 'auto' }}
        leave={{ opacity: 0, height: 0 }}
        config={{ ...config.default, precision: 0.1 }}
      >
        {(item) => (props) => <animated.div style={props}>{item}</animated.div>}
      </Transition>
    </Wrapper>
  )
}

Toasts.propTypes = {
  toasts: PropTypes.shape({
    [TOASTS_TYPE.RELOAD_WEB_PAGE]: PropTypes.bool.isRequired,
  }),
}

export default Toasts
