import styled from 'styled-components'
import { animated } from 'react-spring'

import { lightColors, darkColors } from './constants'

const LiveBar = styled(animated.div)`
  position: absolute;
  height: ${({ theme }) => theme.spacing[4]};
  background: ${({ level, theme }) =>
    `linear-gradient(to right, ${lightColors(theme)[level]}, ${
      darkColors(theme)[level]
    })`};
  left: 0;
  bottom: 0;
  width: auto;
`

export default LiveBar
