import React, { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import InstagramImagePlaceholder from './InstagramImagePlaceholder'
import InstagramImage from './InstagramImage'
import InstagramWrapper from './InstagramWrapper'

const InstagramFeed = ({ inView }) => {
  const { instagramPosts } = useStaticQuery(graphql`
    query InstagramPosts {
      instagramPosts {
        url
        posts {
          caption
          name
          link
        }
      }
    }
  `)

  const instagramImages = instagramPosts.posts.map((post) => ({
    image: `${instagramPosts.url}${post.name}`,
    alt: post.caption,
    link: post.link,
  }))

  return (
    <InstagramWrapper>
      {instagramImages.map((image, index) => (
        <Fragment key={image.alt}>
          <InstagramImagePlaceholder slot={index} />
          <InstagramImage
            key={image.link}
            href={image.link}
            image={image.image}
            alt={image.alt}
            slot={index}
            inView={inView}
          />
        </Fragment>
      ))}
    </InstagramWrapper>
  )
}

export default InstagramFeed
