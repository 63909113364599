import styled from 'styled-components'

import { transitions } from '~styles/constants'

const InstagramImg = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: ${`transform ${transitions.duration.standard}ms ${transitions.easing.sharp}`};
  &:hover {
    transform: scale(1.05);
  }
`

export default InstagramImg
