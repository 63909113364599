import { lighten, darken } from 'polished'
import { css } from 'styled-components'

import { alpha, palette } from './constants'
import { calcFontSize, fluidSpacing } from './helpers'

// size in rem
const typography = {
  baseFontSize: '1',
  baseMinFontSize: 16,
  baseMaxFontSize: 22,
  baseLineHeight: '1.375',
  scaleDown: [1, 0.707, 0.5, 0.354],
  scaleUp: [1, 1.414, 1.999, 2.827, 3.157],
  fontFamily: {
    raleway:
      'Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;',
    pacifico: 'Pacifico, cursive;',
  },
  fontWeight: {
    regular: 400,
    semiBold: 600,
  },
}

const theme = {
  palette: {
    common: {
      black: '#000',
      white: '#FFF',
    },
    primary: {
      lighter: lighten(0.6, palette.primary),
      light: lighten(0.3, palette.primary),
      main: palette.primary,
      dark: darken(0.1, palette.primary),
    },
    secondary: {
      light: lighten(0.1, palette.secondary),
      main: palette.secondary,
      dark: darken(0.1, palette.secondary),
    },
    red: {
      light: lighten(0.3, palette.red),
      main: palette.red,
      dark: darken(0.2, palette.red),
    },
    orange: {
      light: lighten(0.3, palette.orange),
      main: palette.orange,
      dark: darken(0.2, palette.orange),
    },
    green: {
      light: lighten(0.3, palette.green),
      main: palette.green,
      dark: darken(0.2, palette.green),
    },
    blue: {
      light: lighten(0.3, palette.blue),
      main: palette.blue,
      dark: darken(0.2, palette.blue),
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    text: {
      primary: {
        main: `rgba(0, 0, 0, ${alpha.primary})`,
        contrastText: `rgba(255, 255, 255, ${alpha.primary})`,
      },
      secondary: {
        main: `rgba(0, 0, 0, ${alpha.secondary})`,
        contrastText: `rgba(255, 255, 255, ${alpha.secondary})`,
      },
      ternary: {
        main: `rgba(0, 0, 0, ${alpha.ternary})`,
      },
      disabled: {
        main: `rgba(0, 0, 0, ${alpha.disabled})`,
      },
    },
    divider: 'rgba(0, 0, 0, 0.12)',
  },
  typography: {
    body1: {
      mq1234: css`
        font-family: ${typography.fontFamily.raleway};
        font-weight: ${typography.fontWeight.regular};
        /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
        color: rgba(0, 0, 0, ${alpha.primary});
        font-size: ${calcFontSize({ minFont: 18, maxFont: 26 })};
        line-height: 1.5;
      `,
      mqMin: {
        fontSize: '18px',
      },
      mqMax: {
        fontSize: '26px',
      },
    },
    body2: {
      mq1234: css`
        font-family: ${typography.fontFamily.raleway};
        font-weight: ${typography.fontWeight.regular};
        /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
        color: rgba(0, 0, 0, ${alpha.primary});
        font-size: ${calcFontSize({ minFont: 16, maxFont: 22 })};
        line-height: 1.5;
      `,
      mqMin: {
        fontSize: '16px',
      },
      mqMax: {
        fontSize: '22px',
      },
    },
    body3: {
      mq1234: css`
        font-family: ${typography.fontFamily.raleway};
        font-weight: ${typography.fontWeight.regular};
        /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
        color: rgba(0, 0, 0, ${alpha.primary});
        font-size: ${calcFontSize({ minFont: 13, maxFont: 20 })};
        line-height: 1.5;
      `,
      mqMin: {
        fontSize: '13px',
      },
      mqMax: {
        fontSize: '20px',
      },
    },
    body4: {
      mq1234: css`
        font-family: ${typography.fontFamily.raleway};
        font-weight: ${typography.fontWeight.regular};
        /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
        color: rgba(0, 0, 0, ${alpha.primary});
        font-size: ${calcFontSize({ minFont: 12, maxFont: 16 })};
        line-height: 1.5;
      `,
      mqMin: {
        fontSize: '12px',
      },
      mqMax: {
        fontSize: '16px',
      },
    },
    header1: {
      mq1234: css`
        font-family: ${typography.fontFamily.raleway};
        font-weight: ${typography.fontWeight.semiBold};
        /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
        color: rgba(0, 0, 0, ${alpha.primary});
        font-size: ${calcFontSize({ minFont: 24, maxFont: 56 })};
        line-height: 1.5;
      `,
      mqMin: {
        fontSize: '24px',
      },
      mqMax: {
        fontSize: '56px',
      },
    },
    header2: {
      mq1234: css`
        font-family: ${typography.fontFamily.raleway};
        font-weight: ${typography.fontWeight.semiBold};
        /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
        color: rgba(0, 0, 0, ${alpha.primary});
        font-size: ${calcFontSize({ minFont: 20, maxFont: 32 })};
        line-height: 1.5;
      `,
      mqMin: {
        fontSize: '20px',
      },
      mqMax: {
        fontSize: '32px',
      },
    },
    header3: {
      mq1234: css`
        font-family: ${typography.fontFamily.raleway};
        font-weight: ${typography.fontWeight.semiBold};
        /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
        color: rgba(0, 0, 0, ${alpha.primary});
        font-size: ${calcFontSize({ minFont: 18, maxFont: 26 })};
        line-height: 1.5;
      `,
      mqMin: {
        fontSize: '18px',
      },
      mqMax: {
        fontSize: '26px',
      },
    },
    header4: {
      mq1234: css`
        font-family: ${typography.fontFamily.raleway};
        font-weight: ${typography.fontWeight.semiBold};
        /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
        color: rgba(0, 0, 0, ${alpha.primary});
        font-size: ${calcFontSize({ minFont: 16, maxFont: 22 })};
        line-height: 1.5;
      `,
      mqMin: {
        fontSize: '16px',
      },
      mqMax: {
        fontSize: '22px',
      },
    },
    header5: {
      mq1234: css`
        font-family: ${typography.fontFamily.raleway};
        font-weight: ${typography.fontWeight.semiBold};
        /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
        color: rgba(0, 0, 0, ${alpha.primary});
        font-size: ${calcFontSize({ minFont: 13, maxFont: 20 })};
        line-height: 1.5;
      `,
      mqMin: {
        fontSize: '13px',
      },
      mqMax: {
        fontSize: '20px',
      },
    },
    instagram: {
      mq1234: css`
        font-family: ${typography.fontFamily.pacifico};
        font-weight: ${typography.fontWeight.semiBold};
        /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
        color: rgba(0, 0, 0, ${alpha.primary});
        font-size: ${calcFontSize({ minFont: 18, maxFont: 26 })};
        line-height: 1.5;
      `,
      mqMin: {
        fontSize: '16px',
      },
      mqMax: {
        fontSize: '22px',
      },
    },
  },
  spacing: {
    '1': '0.0625rem',
    '2': '0.125rem',
    '4': '0.25rem',
    '8': '0.5rem',
    '12': '0.75rem',
    '16': '1rem',
    '24': '1.5rem',
    '32': '2rem',
    '40': '2.5rem',
    '48': '3rem',
    '64': '4rem',
    '128': '8rem',
    '256': '16rem',
  },
  fluidSpacing,
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
}

export default theme
