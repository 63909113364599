/**
 *
 * Typography
 *
 */

import styled from 'styled-components'
import PropTypes from 'prop-types'

import { maxWidthStyle } from '~components/MaxWidthWrapper'
import { mapThemePropsToCss } from '~styles/helpers'

const Typography = styled.p`
  ${mapThemePropsToCss};
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: ${(props) => props.align};
  white-space: ${(props) => props.preWrap && 'pre-wrap'};
  text-transform: ${(props) => props.uppercase && 'uppercase'};
  ${(props) => props.limit && maxWidthStyle};
  color: ${(props) => props.color || props.theme.palette.text.primary.main};
  ${(props) => props.styles};
`

Typography.defaultProps = {
  type: 'body2',
}

Typography.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  type: PropTypes.string,
}

export default Typography
