import styled from 'styled-components'

import { shadows } from '~styles/constants'

import { lightColors, mainColors } from './constants'
import Svg from './Icons/Svg'

const Message = styled.div`
  border-radius: ${({ theme }) => theme.spacing[2]};
  box-shadow: ${shadows[1]};
  background: ${({ level, theme }) => lightColors(theme)[level]};
  padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[32]}`};
  width: 400px;
  max-width: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  padding-right: ${({ theme }) => theme.spacing[32]};

  &:hover ${Svg} {
    fill: ${({ level, theme }) => mainColors(theme)[level]};
  }
`

export default Message
