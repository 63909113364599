import React from 'react'
import Helmet from 'react-helmet'
import Loadable from '@loadable/component'

// import config from '~utils/siteConfig'
import GlobalStyle from '~styles/global'
import Messages from '~components/Messages'
import Footer from '~components/Footer'
import { NODE_ENV } from '~utils/constants'

const LoadableDevTools = Loadable(() => import('~components/DevTools'))

const preconnectUrls = [
  'https://www.google-analytics.com',
  'https://res.cloudinary.com',
]

const AppLayout = ({ children }) => (
  <>
    <Helmet>
      {preconnectUrls.map((link) => (
        <link key={link} rel="preconnect" href={link} crossOrigin="anonymous" />
      ))}
    </Helmet>
    {children}
    <GlobalStyle />
    <Messages />
    {NODE_ENV !== 'production' && <LoadableDevTools />}
    <Footer />
  </>
)

export default AppLayout
